<template>
  <v-card class="mb-7">
    <v-card-title>
      <span>Order List</span>
    </v-card-title>

    <v-data-table
      :headers="tableColumnHeaders"
      :items="orders"
      :options.sync="options"
      :items-per-page="99"
      hide-default-footer
    >
      <!-- total -->
      <template #[`item.total`]="{item}">
        {{ item.total | localPrice({ currency: item.currency }) }}
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        {{ item.created_at | date }}
      </template>

      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          :color="item.status === 'completed' ? 'success' : 'secondary'"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <!-- Action -->
      <template #[`item.action`]="{item}">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              :to="{ name: 'checkout-order-view', params: { id: item.checkout_id } }"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiEye }}
                </v-icon>
                <span>View</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import useChannelUser from '@/modules/user/views/useChannelUser'
import { date, localPrice } from '@core/utils/filter'
import { mdiDotsVertical, mdiEye } from '@mdi/js'

export default {
  filters: { localPrice, date },

  props: {
    user: { type: Object, required: true },
  },

  setup(props) {
    const tableColumnHeaders = [
      { text: 'TOTAL', value: 'total' },
      { text: 'DATE', value: 'created_at' },
      { text: 'STATUS', value: 'status', sortable: false },
      { text: 'ACTION', value: 'action', sortable: false },
    ]

    const { orders, loadOrders, options } = useChannelUser({ user_id: props.user.id })

    loadOrders({ user_id: props.user.id })

    return {
      tableColumnHeaders,
      orders,
      options,
      icons: {
        mdiDotsVertical,
        mdiEye,
      },
    }
  },
}
</script>
